<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-data-table
      fixed-header
      :headers="headers"
      :items="checkFilterVar ? fileListFiltered : rows"
      class="elevation-1 vcad-table"
      hide-default-footer
      :search="search"
      height="700"
      style="width: 90%"
      item-key="slotKey"
      v-if="headers"
      :loading="isLoading"
      show-expand
      :custom-filter="customFilter"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <div
          class="primary justify-space-between align-center d-flex lighten-2 vcad-table-top pa-3"
        >
          <div>
            <h3 class="ml-1 white--text">Vcad slots</h3>
          </div>
          <div class="d-flex align-center">
            <file-list-filter
              :items="rows"
              @filterApply="checkFilter"
              v-model="fileListFiltered"
            />
            <!-- SEARCH -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by file name or file SLOT ID"
              hide-details
              clearable
              solo
              dense
              style="width: 400px"
            ></v-text-field>
          </div>
        </div>
      </template>

      <!-- IMPORT DATE COL -->
      <template v-slot:[`item.dateEndConv`]="{ item }">
        <span>{{ item.formattedEndDate }}</span>
      </template>

      <!-- STATUS COL -->
      <template v-slot:[`item.statusObj`]="{ item }">
        <span class="d-inline-flex align-center mx-auto">
          <span>{{ item.statusObj.txt }}</span>
          <v-icon :color="item.statusObj.color" class="mx-2">{{
            item.statusObj.ico
          }}</v-icon>
        </span>
      </template>

      <!-- ERROR COL -->
      <template v-slot:[`item.error`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 250px"
              v-on="on"
              >{{ item.error }}</span
            >
          </template>
          <span>{{ item.error }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          @click="goToFolder(item)"
          outlined
          small
          max-width="12em"
          color="primary"
          class="vcad-btn"
        >
          <v-icon>mdi-folder</v-icon>
          GO TO FOLDER
        </v-btn>
      </template>

      <!-- EXPANSION -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-5">
          <v-data-table
            :headers="expansionTableHeaders"
            :items="item.groupedList"
            item-key="id"
            class="elevation-0 vcad-tables"
            hide-default-footer
            dense
          >
            <!-- ACTIONS -->

            <template v-slot:[`item.action`]="{ item }">
              <v-menu
                bottom
                rounded
                offset-x
                close-on-click
                close-on-content-click
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    class="ma-1"
                    v-on="on"
                    @click="setViews(item)"
                  >
                    Actions
                  </v-btn>
                </template>

                <slot-actions class="flex-column pa-2" :slotItem="item">
                  <template v-slot:btn-actions> </template>
                </slot-actions>
              </v-menu>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <!-- DELETE DIALOG -->
    <vcad-dialog
      v-model="ShowDeleteDialog"
      title="Clear slot"
      :contentProps="{ fileName: currentItem.name }"
    >
      <template v-slot:content="{ contentData }">
        <p class="text-center">
          You are about to clear the selected slot from the current loaded model
          :
          <i
            ><b>{{ contentData.fileName }}</b></i
          >
        </p>
        <p class="text-center">
          Previously generated reports may not work correctly. Are you sure you
          wish to proceed?
        </p>
      </template>

      <template v-slot:actions>
        <div
          class="d-inline-flex justify-end"
          style="width: 100%; height: 100%"
        >
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click="ShowDeleteDialog = false"
            >CLOSE</v-btn
          >
          <v-btn
            outlined
            small
            color="primary"
            class="vcad-btn"
            @click="clearSlot(currentItem)"
            >CONFIRM</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { vcadFileStatusDict } from "../utils/VCadItemStatusUtil";
import FileListFilter from "./FileListFilter.vue";
import VcadDialog from "./VcadDialog.vue";
import SlotActions from "../components/SlotActions.vue";
import { formattedDate } from "../utils/FormattedDate";

export default {
  components: {
    "slot-actions": SlotActions,
    "vcad-dialog": VcadDialog,
    FileListFilter,
  },
  data() {
    return {
      fileListFiltered: [],
      checkFilterVar: false,
      headers: [],
      tableFilter: "",
      importingFile: false,
      ShowDeleteDialog: false,
      currentItem: {},
      allowedTypeCheck: false,
      isLoading: false,
      resetStatusDialog: false,
      expansionTableHeaders: [],
      actionBarHeight: 0,
      filterApply: null,
      tmpActionBar: null,
      search: "",
      colorSnackbar: "",
    };
  },
  computed: {
    ...mapGetters([
      "currentForgeFileData",
      "vcadFileList",
      "vcadUserInfo",
      "accountId",
      "vCadFileStatus",
    ]),
    filterSet() {
      if (this.filterApply) {
        return true;
      } else {
        return false;
      }
    },
    toolbarFileStatus() {
      let fs = this.vCadFileStatus;
      return fs;
    },
    getConfigurationUserVcad() {
      try {
        return JSON.parse(this.vcadUserInfo);
      } catch (error) {
        return null;
      }
    },
    enableProfilesVcad() {
      try {
        return this.getConfigurationUserVcad.profilesEnable;
      } catch (error) {
        return null;
      }
    },
    rows() {
      // let rows = this.vcadFileList.map((row) => {
      //   // Set Status Object
      //   row.statusObj = vcadFileStatusDict[row.status];
      //   return row;
      // });

      if (!this.vcadFileList || this.vcadFileList.length == 0) {
        return [];
      }

      // Map missing data
      let fixedKeys = this.vcadFileList.map((item) => {
        item.slotKey = item.slotKey || item.id;
        item.statusObj = vcadFileStatusDict[item.status];

        const info = JSON.parse(item.info);
        item.versionDescr = info.b360Item
          ? "V " + info.b360Item.revisionDisplayLabel
          : null;
        item.typeDescr = info.b360Item ? info.b360Item.type : null;

        //TODO: Gestire un tipo che determini se si ta guardando una fase, versione o view

        // Format import date
        // item.dateEndConv = formattedDate(item.dateEndConv);
        item.formattedEndDate = formattedDate(item.dateEndConv);
        // Add folder name and import user
        item.folderName = info.b360Item.folderName;
        item.importUser = info.email;

        return item;
      });

      // Create row dictionary in form { *slotKey* : {*rowData*} }
      let rowDictionary = fixedKeys.reduce((dict, curr) => {
        // If missing, create key item
        if (!dict[curr.slotKey]) {
          dict[curr.slotKey] = {
            name: JSON.parse(curr.info).fileName,
            label: curr.label,
            dateEndConv: curr.dateEndConv,
            formattedEndDate: curr.formattedEndDate,
            statusObj: curr.statusObj,
            error: curr.error,
            slotKey: curr.slotKey,
            groupedList: [],
            folderName: curr.folderName,
            importUser: curr.importUser,
            slotId: curr.id,
          };
        }

        // Add to gouped item list
        dict[curr.slotKey].groupedList.push(curr);
        return dict;
      }, {});

      // Convert dictionary to array of rows
      let rows = Object.values(rowDictionary)
        // Set number of items in expansion
        .map((row) => {
          row.nbrItems = row.groupedList.length;
          return row;
        });

      return rows;
    },
    // tableOptions: {
    //   get() {
    //     let screenSize = this.$vuetify.breakpoint.name;
    //     return {
    //       itemsPerPage: screenSize == "xl" ? 10 : 5,
    //     };
    //   },
    //   set() {},
    // },
  },
  watch: {
    toolbarFileStatus(value) {
      if (value == 0) {
        this.importingFile = false;
      }
      if (value == 1) {
        this.importingFile = true;
      }

      //Update ActionBar size
      this.$nextTick().then(() => {
        this.actionBarHeight = this.tmpActionBar?.clientHeight;
      });
    },
    // Whatch initial seleciton of user account
    accountId(to) {
      if (to) {
        // Load file list
        this.loadFiles();
      }
    },
  },
  methods: {
    ...mapActions([
      "showSnackbar",
      "setVcadFileList",
      "setFileData",
      "setShowPreview",
      "deleteVcadFile",
      "setVcadTemplateList",
      "setShowTemplates",
      "setCurrentViews",
      "restartConversion",
    ]),
    setViews(item) {
      let info = JSON.parse(item.info);
      this.setCurrentViews(info.b360Item);
    },
    closeSnack() {
      this.snackbarIsVisible = false;
    },
    goToFolder(slots) {
      const firstSlot = JSON.parse(slots.groupedList[0].info);
      const parentId = firstSlot.b360Item.parentId;
      const projectId = firstSlot.b360Item.projectId;
      if (parentId) {
        this.$router.push(`/b360-doc-manager/${projectId}/${parentId}`);
      } else {
        this.showSnackbar({
          text: "Sorry, redirection is not available for this file. Please try restarting the file conversion process.",
          color: "warning",
          timeoutTime: 6000,
        });
      }
    },
    onSupportedTypesLoad(supportedTypes) {
      this.allowedTypeCheck = supportedTypes.length > 0;
    },
    closeFilterDialog(value) {
      this.filterDialog = value;
    },
    resetVcadFileStatus(item) {
      this.restartConversion({
        slotGuid: item.id,
        filterId: this.filterApply?.profileId,
      }).finally(() => {
        this.resetStatusDialog = false;
      });
    },
    customFilter(value, search, item) {
      if (!search) {
        return true;
      }
      // Check row
      let itemProps = [
        item.name,
        item.dateEndConv,
        item.slotKey,
        item.importUser,
        item.slotId,
      ];
      const rowIsValid =
        JSON.stringify(itemProps)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1;
      return rowIsValid;
    },
    /**
     * Set the current model and show preview.
     */
    async setModelAndShow(vcadFileInfo) {
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;

      await this.setFileData({ slot: 1, data: payloadData });
      // .then( (fd) => {
      //   this.setCurrentViews(fd.b360Item)
      // });
      await this.setShowPreview({ show: true, mode: 0 });
    },
    /**
     * Set the values of the currentItem for deletion
     */
    setCurrentItem(item) {
      this.currentItem = Object.assign({}, item);
    },
    /**
     * Delete file in slot and clear slot for new use
     */
    clearSlot(vcadFileData) {
      this.deleteVcadFile({ guid: vcadFileData.id })
        .then(() => {
          this.ShowDeleteDialog = false;
          this.currentItem = Object.assign({});
          this.loadFiles();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Display list of templates for download
     */
    async showTemplateList(vcadFileInfo) {
      let vcadType = vcadFileInfo.type;
      let payloadData = JSON.parse(vcadFileInfo.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;

      // Concurrent promise execution
      let p0 = this.setFileData({ slot: 1, data: payloadData }).then((fd) => {
        this.setCurrentViews(fd.b360Item);
      });
      let p1 = this.setVcadTemplateList(vcadType);
      let p2 = this.setShowTemplates(true);
      await Promise.all([p0, p1, p2])
        .then(() => console.log("resolved all promises"))
        .catch((err) => console.error("My error: ", err));
    },
    /**
     * Load Vcad files.
     */
    loadFiles() {
      this.isLoading = true;
      this.setVcadFileList()
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkFilter(value) {
      this.checkFilterVar = value;
    },
  },
  mounted() {
    this.loadFiles();

    // Setup table headers
    this.headers = [
      {
        text: "File name",
        value: "name",
        align: "center",
        sortable: true,
        filterable: true,
        width: "30%",
      },
      {
        text: "Import date",
        value: "dateEndConv",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Status",
        value: "statusObj",
        align: "center",
        sortable: true,
        filterable: true,
        sort: (a, b) => (a.txt >= b.txt ? 1 : -1),
      },
      {
        text: "Error message",
        value: "error",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "No. items",
        value: "nbrItems",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Folder name",
        value: "folderName",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
        filterable: false,
      },
    ];

    // Setup table header for expansion table
    this.expansionTableHeaders = [
      {
        text: "File name",
        value: "name",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Version",
        value: "versionDescr",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Item type",
        value: "typeDescr",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Import user",
        value: "importUser",
        align: "center",
        sortable: true,
        filterable: true,
      },
      {
        text: "Actions",
        value: "action",
        align: "center",
        sortable: false,
        filterable: false,
      },
    ];
  },
};
</script>

<style lang="css" scoped>
.vcad-table-top {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.vcad-table td {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}
</style>